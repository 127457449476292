import React, { Fragment, useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Icon, Link } from '@/atoms';

import { toArray } from '@/utils/utils';

import type { FunctionComponent } from 'react';
import type { TypeSeeMoreProps } from './types';

/**
 * SeeMore
 */
export const SeeMore: FunctionComponent<TypeSeeMoreProps> = ({
  children,
  expanded = false,
  variant = 'primary',
  labels,
  heigthPx,
  numberOfElements,
  icon,
  className,
}: TypeSeeMoreProps) => {
  const [isExpanded, setExpanded] = useState(expanded);
  const { t } = useTranslation('common');
  const toggle = (state: boolean) => {
    setExpanded(state);
  };
  const firstChild = toArray(children)[0];
  const firstChildRef = useRef(null);
  const [seeMore, setSeeMore] = useState(expanded);
  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    setSeeMore(firstChildRef?.current?.clientHeight > heigthPx);
  }, [firstChildRef]);

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    setSeeMore(firstChildRef?.current?.clientHeight > heigthPx);
  }, []);

  if (!labels) {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    labels = t('components.atoms.seeMore.labels', 'common', {
      returnObjects: true,
    });
  }

  return (
    <Fragment>
      {numberOfElements ? (
        toArray(children).map((child, index) => (
          <div
            key={index}
            className={`${
              index < numberOfElements
                ? 'h-full min-h-full min-w-full opacity-100'
                : isExpanded
                ? 'h-full min-h-full min-w-full opacity-100'
                : 'h-0 opacity-0 '
            } col-span-2 transition-all duration-500 md:col-span-1`}
          >
            <child.type
              {...child.props}
              {...(typeof child.type === 'function'
                ? {
                    seen:
                      index < numberOfElements
                        ? true
                        : isExpanded
                        ? true
                        : false,
                  }
                : {})}
            />
          </div>
        ))
      ) : (
        <div
          style={{
            maxHeight: isExpanded ? '100%' : `${heigthPx}px`,
          }}
          className={`relative h-full  overflow-hidden ${
            !isExpanded && seeMore
              ? " after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[35px] after:bg-[linear-gradient(rgb(248_247_246/30%)_0%,#fff7f3_100%)] after:content-['']"
              : ''
          }`}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */}
          {firstChild && React.cloneElement(firstChild, { ref: firstChildRef })}
        </div>
      )}

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-ignore*/}
      {children?.length > numberOfElements || seeMore ? (
        <div
          className={`z-10 col-start-1 col-end-[-1] mt-2 flex w-full cursor-pointer flex-row items-center justify-center gap-2 ${className}`}
          onClick={() => toggle(!isExpanded)}
        >
          <Link as="span" variant={variant} arrow={false}>
            {isExpanded ? labels?.viewLess : labels?.viewMore}
          </Link>

          {icon ? (
            <Icon
              className={isExpanded ? 'rotate-180' : ''}
              height={10}
              width={10}
              src={icon}
            />
          ) : null}
        </div>
      ) : null}
    </Fragment>
  );
};

SeeMore.displayName = 'SeeMore';
