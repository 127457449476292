import useTranslation from 'next-translate/useTranslation';
import { oneLine } from 'common-tags';

import {
  Caption,
  ConditionalWrapper,
  Grid,
  Icon,
  Prose,
  SeeMore,
} from '@/atoms';

import type {
  TypeTextAlignVariant,
  TypeTextColorVariant,
} from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeSectionTextProps } from './types';

const TEXT_ALIGN_MAPS: Record<TypeTextAlignVariant, string> = {
  center: 'flex md:justify-center md:text-center',
  end: 'flex md:justify-end md:text-end',
  start: 'flex md:justify-start md:text-start',
};

const TEXT_ALIGN_MAPS_MOBILE: Record<TypeTextAlignVariant, string> = {
  center: 'flex justify-center text-center',
  end: 'flex justify-end text-end',
  start: 'flex justify-start text-start',
};

export const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'text-primary-400',
  secondary: 'text-secondary-400',
  alternative: 'text-white',
};

export const VARIANT_MAPS_TITLE: Record<TypeTextColorVariant, string> = {
  primary: '!text-brownie-500',
  secondary: 'text-secondary-400',
  alternative: 'text-white',
};
export const SectionText: FunctionComponent<TypeSectionTextProps> = ({
  className = '',
  caption,
  desc,
  direction,
  textAlign,
  textAlignMobile,
  title,
  caracteristics,
  variant,
  caracCols = 2,
  directionMobile,
  showSeeMore = false,
  subtitle,
}: TypeSectionTextProps) => {
  const { t } = useTranslation('common');

  const rowCarac =
    direction === 'row' && directionMobile === 'row' && !caracteristics;

  const captionAlignment =
    direction === 'column'
      ? textAlign === 'center'
        ? 'md:justify-center md:items-center'
        : textAlign === 'end'
        ? 'md:justify-end md:items-end'
        : 'md:justify-start md:items-start'
      : '';

  const MobileAlignment =
    direction === 'column'
      ? textAlignMobile === 'center'
        ? 'justify-center items-center'
        : textAlignMobile === 'end'
        ? 'justify-end items-end'
        : 'justify-start items-start'
      : '';

  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore */
  const labels: { viewMore: string; viewLess: string } = t(
    'components.atoms.seeMore.labels',
    'common',
    { returnObjects: true }
  );

  return (
    <div
      className={oneLine`
        flex gap-8 md:mb-6 md:gap-16
        ${
          rowCarac
            ? 'flex-row'
            : `
              ${directionMobile === 'row' ? 'flex-row ' : 'flex-col'}  
              ${
                direction === 'row'
                  ? 'justify-between md:flex-row '
                  : 'md:flex-col'
              }
            `
        }
        ${className}
      `}
    >
      <div
        className={
          rowCarac
            ? 'w-full max-w-fit md:w-full'
            : direction === 'row'
            ? 'w-full max-w-fit md:w-1/2'
            : ''
        }
      >
        {caption && (
          <Caption
            align={textAlign}
            className={`mb-4 ${captionAlignment} ${MobileAlignment}`}
            mobileAlignment={textAlignMobile ?? textAlign}
            variant={variant}
          >
            {caption}
          </Caption>
        )}

        <div
          className={oneLine`
            max-w-[802px] justify-between
            ${TEXT_ALIGN_MAPS[textAlign]}
            ${TEXT_ALIGN_MAPS_MOBILE[textAlignMobile ?? textAlign]}   
          `}
        >
          <h2
            className={oneLine`
              text-heading text-heading-mobile--h2 !font-normal md:text-heading--h2 
              ${VARIANT_MAPS_TITLE[variant]}
              ${!caracteristics ? 'w-full' : 'sm:w-1/2'}
            `}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>

        {subtitle && (
          <div className="mt-6">
            <h3
              className={`text-heading text-heading--h5 ${VARIANT_MAPS[variant]}`}
            >
              {subtitle}
            </h3>
          </div>
        )}
      </div>

      {desc || caracteristics ? (
        <div
          className={oneLine`
            flex max-w-[802px]  flex-col gap-4
            ${showSeeMore ? 'sm:flex-col' : 'sm:flex-row'}
            ${rowCarac ? 'w-full md:w-1/2' : ''}
            ${direction === 'row' && !caracteristics ? 'w-full md:w-1/2' : ''} 
           
          `}
        >
          {desc && (
            <ConditionalWrapper
              condition={showSeeMore}
              wrapper={(children) => (
                <SeeMore
                  heigthPx={150}
                  expanded={false}
                  labels={{
                    viewLess: labels.viewLess,
                    viewMore: labels.viewMore,
                  }}
                >
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                  {/* @ts-ignore*/}
                  {children}
                </SeeMore>
              )}
            >
              <Prose
                html={desc}
                className={oneLine`
                  mb-5  break-keep !text-[20px]  !font-light  leading-[28px] md:mb-6
                  ${!caracteristics ? 'w-full' : 'w-full md:w-1/2'}
                  ${VARIANT_MAPS[variant]}
                  ${TEXT_ALIGN_MAPS[textAlign]}
                `}
              />
            </ConditionalWrapper>
          )}

          {caracteristics ? (
            <div className='sm:before:content-[" "] flex w-full flex-row sm:w-1/2 sm:before:mr-5 sm:before:block sm:before:h-auto sm:before:w-px sm:before:border-l sm:before:border-primary-400 '>
              <Grid
                className="w-full !gap-3 text-primary-400 "
                xs={caracCols}
                md={caracCols}
                lg={caracCols}
              >
                {caracteristics.map((child, index) => (
                  <Grid.Item className="flex flex-row gap-1" key={index}>
                    <Icon src={child.icon} width={20} height={20} />
                    <span className="text-body text-body--secondary">
                      {child.text}
                    </span>
                  </Grid.Item>
                ))}
              </Grid>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

SectionText.displayName = 'SectionText';
