import { oneLine } from 'common-tags';
import { Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import Derecha from '@/assets/icons/DerechaSinFondo.svg';
import Izquierda from '@/assets/icons/IzquierdaSinFondo.svg';
import { toArray } from '@/utils/utils';

import type { FunctionComponent } from 'react';
import type { TypeSwiperCustomProps } from './types';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';

/**
 * SwiperCustom
 */
export const SwiperCustom: FunctionComponent<TypeSwiperCustomProps> = ({
  slidesPerView,
  gap,
  children,
  slidesPerViewMobile,
  gapMobile,
  navigation,
  scrollbar,
  slideMaxWidth,
  direction = 'column',
  variants = 'primary',
  slideGrow = false,
  ...props
}: TypeSwiperCustomProps) => (
  <Swiper
    spaceBetween={
      toArray(children).length > 1 ? (gapMobile ? gapMobile : gap) : 0
    }
    slidesPerView={slidesPerViewMobile ?? slidesPerView}
    breakpoints={{
      700: {
        slidesPerView: slideGrow ? toArray(children).length : slidesPerView,
        spaceBetween: toArray(children).length > 1 ? gap : 0,
      },
    }}
    centerInsufficientSlides
    scrollbar={{
      hide: false,
      draggable: true,
      enabled: scrollbar,
      el: '.swiper-scrollbar-custom',
    }}
    navigation={{
      enabled: navigation,
      nextEl: '.swiper-button-next-custom',
      prevEl: '.swiper-button-prev-custom',
    }}
    modules={[
      ...(scrollbar ? [Scrollbar] : []),
      ...(navigation ? [Navigation] : []),
    ]}
    wrapperClass="!h-auto "
    {...props}
  >
    {/* Cards */}

    {toArray(children)
      .filter(Boolean)
      .map((child, index) => (
        <SwiperSlide
          {...(slideMaxWidth && { style: { maxWidth: slideMaxWidth } })}
          className={oneLine`
          mb-3 !h-auto
          ${slideGrow ? 'flex-grow' : ''}
          ${slideMaxWidth ? 'md:w-full' : 'w-full'}
        `}
          key={index}
        >
          {child}
        </SwiperSlide>
      ))}

    {/* Progress Bar */}
    {(navigation || scrollbar) && (
      <div
        className={`mx-auto mt-10 flex w-4/5 flex-row items-center gap-4 lg:w-1/2 ${
          direction === 'row' ? 'lg:ml-56' : 'lg:mr-0'
        }`}
      >
        {scrollbar && (
          <div
            className={oneLine`
            swiper-scrollbar-custom h-1 w-full
            ${
              variants === 'primary'
                ? 'bg-primary-400'
                : variants === 'secondary'
                ? 'bg-secondary-400'
                : 'bg-tertiary-400'
            }
          `}
          />
        )}

        {/* Arrows left-right */}

        {navigation && (
          <div className="ml-auto hidden flex-row gap-3 text-primary-400 md:flex">
            <Izquierda
              width={32}
              heigth={32}
              className="swiper-button-prev-custom cursor-pointer"
              role="button"
            />

            <Derecha
              width={32}
              heigth={32}
              className="swiper-button-next-custom cursor-pointer"
              role="button"
            />
          </div>
        )}
      </div>
    )}
  </Swiper>
);

SwiperCustom.displayName = 'SwiperCustom';
